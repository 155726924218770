import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_stdio = _resolveComponent("stdio")

  return (_openBlock(), _createBlock(_component_stdio, {
    history: $data.history,
    inputting: !!$data.resolveInput,
    onSubmit: $options.handleInput
  }, null, 8, ["history", "inputting", "onSubmit"]))
}