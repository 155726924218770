import _ from 'lodash'

import store from '@/store'
import * as keystroke1 from '../sounds/keystroke1.mp3'; import * as keystroke2 from '../sounds/keystroke2.mp3'; import * as keystroke3 from '../sounds/keystroke3.mp3'; import * as keystroke4 from '../sounds/keystroke4.mp3'; import * as keystroke5 from '../sounds/keystroke5.mp3'; import * as keystroke6 from '../sounds/keystroke6.mp3'; var soundFiles = {"keystroke1": keystroke1,"keystroke2": keystroke2,"keystroke3": keystroke3,"keystroke4": keystroke4,"keystroke5": keystroke5,"keystroke6": keystroke6,}

const keystrokes = _.map(soundFiles, (path) => new Audio(path.default))

export default () => {
    const sound = _.sample(keystrokes)
    const isPlaying = (!sound.paused || sound.currentTime) && !sound.ended
    if (!store.state.sound || sound.readyState < 4 || isPlaying) return
    sound.play()
}
