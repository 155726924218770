import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dcdd43d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cake" }
const _hoisted_2 = {
  key: 0,
  class: "cake__art"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_stdio = _resolveComponent("stdio")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_stdio, {
      class: "cake__shell",
      history: _ctx.history,
      inputting: !!_ctx.resolveInput,
      onSubmit: _ctx.handleInput
    }, null, 8, ["history", "inputting", "onSubmit"]),
    ($data.art)
      ? (_openBlock(), _createElementBlock("pre", _hoisted_2, _toDisplayString($data.art), 1))
      : _createCommentVNode("", true)
  ]))
}