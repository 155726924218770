import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cf47d81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fire" }
const _hoisted_2 = {
  class: "fire__flames",
  ref: "flames"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_stdio = _resolveComponent("stdio")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("pre", _hoisted_2, null, 512),
    _createVNode(_component_stdio, {
      class: "fire__shell",
      history: _ctx.history,
      inputting: !!_ctx.resolveInput,
      onSubmit: _ctx.handleInput
    }, null, 8, ["history", "inputting", "onSubmit"])
  ]))
}